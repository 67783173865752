.ViewOnMob {
  display:none;

}

.HideOnMob{
  display: block;
}
@media screen and (max-width: 480px){
.pathava{
  height: 250px;
}
}
@media screen and (max-width: 480px){
  .HideOnMob{
    display:none;
  }
}

@media screen and (max-width: 480px)
{
.ViewOnMob{
  display:block;
}
}

.chandni{
  scroll-behavior: smooth;
}


.bar2{
  background: #2E2E2E;
  padding-bottom: 5px;
}

.but2{
  color:white;
  background-color: grey;
  width: 140px;
}

.cont1{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  background-color: #F8F8F8;


}

.cont12{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  overflow: auto;  
  background-color: #F8F8F8;

}
.cont123{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  overflow:auro;
  height: 330px;
  background-color: #F8F8F8;


  
}



.heading{
  font-style: normal;
  font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  text-shadow: 2px 2px 4px #000000;
  font-size: 50px;
  letter-spacing: 0px;
  font-weight: 600;
  
}


.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 50px;
   font-weight: 600;
   margin-top: 80px;
 }

@media screen and (max-width: 480px){

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 20px;
   font-weight: 100;
   margin: 10px;
   min-height: 400px;
  }
}

.image1{
   
  height: 550px;
  display:block;
  margin-left:auto;
  margin-right: auto;
}

.container1 {
  position: relative;
  width: 10%;
  float: left;
  margin-left: 20px;
}

.image1 {
  display: block;
  width: 70%;
  height: auto;
}


.cortry{
  min-height: 600px;
  background-image: url(http://www.emanjha.com/emanjha/images/5c4f1bf949fd7.jpg);
  background-repeat: no-repeat; 
  background-size: 1700px 600px;
  background-position: center top;


 } 
 .cortry1{
    width: 100%;   
    background-size: 100%;
    background-repeat: no-repeat;
    height: 600px;
 }

.bottomrt{
   position: absolute;
  bottom: 0px;
  right: 0px;
  width: 300px;
}
.bottom{
  position: absolute;
  bottom:0px;
}

@media screen and (max-width: 480px){
  .overlay{
    display:none;
  }
  .text1{
    display:none;
  }
  .overlay:hover {
    display: none;
  }
  .container1:hover {
    display: none;
  }
}

@media screen and (max-width:480px){

  .SmallOnMob {
    font-size: 10px;
    padding: 5px;
  }

}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 70%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
  display: block;
}

.container1:hover .overlay {
  opacity: 0.7   ;
}

.text1 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
