.conta{
	border:3px solid #808080;
	width: 799px;
	height: 90px;
	background-color: gray;
	color: white;
	padding: 0px;
	background-image: linear-gradient(to bottom right, #404040, #606060);

}
.hastar{
	font-size: 40px;
	
}
.hi{
	float:right;
	list-style-type: none;
	padding: 0px;

}
