.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 60px;
   font-weight: 600;
   margin-top: 80px;
 }

@media screen and (max-width: 480px){

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 25px;
   font-weight: 600;
   margin: 10px;
   min-height: 400px;
  }
}


.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: "Show more";
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: "Show less";
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5em;
  color: #666;
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}

