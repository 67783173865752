.smalltext{
  font-size: 13px;
}
.smalltext1{
  font-size: 14px;
}


.pahayche{
    border-style: solid;
    border-color: grey;
    border-width: 2px;
    border-radius: 5px;
    background-color: #F0F0F0;

}
.ajnabehomie{
  border-style: solid;
  border-color: grey;
  border-width: 2px;
  border-radius: 3px;
  height: 55px;
}

.hastarcacg{
  color: black;
  text-align: center;
}

.heathen{
    border:2px solid #808080;
    padding: 20px;
    background-color: #F0F0F0;
    max-width: 100%;
}

.chop{
	width:270px; 
}

.lightgrey{
	background-color: #DCDCDC;
}


.bulletproof{
	height: 50px;

}