.title h4 {
    text-align:center;
    color:black;
    font-weight: 500;
   font-family:Segoe,Segoe UI,DejaVu Sans,Trebuchet MS,Verdana," sans-serif";
    padding-top :6px;
  }

  .title h4 a{
    color: black;
    text-align:center;
    font-family: 'Comic sans ms';   
  }
  
  .koi{
    font-size: 14px;
  }

 .kolbi{
  margin-top: -20px;
}

.hyachyat{
  float: right;
}
.quick{
  font-size: 18px;
}

  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}


.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.5);
}

@media only screen and (min-width : 150px) and (max-width : 480px){
  .title h4 a{
    font-size: 16px;
    font-weight: 300;
  }
}


.intern{
  overflow: auto;
  font-size: 15px;
}
.intern1{
  float: left;
}


.ciga{
  overflow: auto;
}