.row1  {
    padding: 1rem;
    background-color: #eaeef0;
    border: 2px solid rgb(73, 18, 18);
    color: rgb(0, 0, 0);
    text-align: center;
    margin:20px;
    border-radius: 10px;
  }
  

  