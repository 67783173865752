.hds{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}
.pqr{
  margin-top: 8px;
}
@media screen and (max-width: 480px)
{

.navbar .container {
  width: 100%;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
.navbar .nav-item .nav-link {
  padding-right: 6px;
  padding-left: 0px;
}

}
@media screen and (max-width: 934px)
{
  .navbar .nav-item .nav-link {
  padding-right: 6px;
  padding-left: 0px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
div.pqr.nav-item {
  padding-left: 0px;
  width: 0px;
}
form.hds.form-inline {
  margin-left: 0px;
  margin-right: 0px;
}

}