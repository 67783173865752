@media only screen and (min-width: 600px){
	.chaapchaap{
		width: 70%;
		

}
}

.chaapchaap{
	border-style: double;
		border-radius: 10px;
}