.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo{
	width: 10rem;
}

.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}
.row1  {
    padding: 1rem;
    background-color: #eaeef0;
    border: 2px solid rgb(73, 18, 18);
    color: rgb(0, 0, 0);
    text-align: center;
    margin:20px;
    border-radius: 10px;
  }
  

  
.title h4 {
    text-align:center;
    color:black;
    font-weight: 500;
   font-family:Segoe,Segoe UI,DejaVu Sans,Trebuchet MS,Verdana," sans-serif";
    padding-top :6px;
  }

  .title h4 a{
    color: black;
    text-align:center;
    font-family: 'Comic sans ms';   
  }
  
  .koi{
    font-size: 14px;
  }

 .kolbi{
  margin-top: -20px;
}

.hyachyat{
  float: right;
}
.quick{
  font-size: 18px;
}

  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}


.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

@media only screen and (min-width : 150px) and (max-width : 480px){
  .title h4 a{
    font-size: 16px;
    font-weight: 300;
  }
}


.intern{
  overflow: auto;
  font-size: 15px;
}
.intern1{
  float: left;
}


.ciga{
  overflow: auto;
}
/* Colors */
/*
dark blue: #476A6F
light blue: #258EA6
dark green: #519E8A
medium green: #7EB09B
light green: #A3C4BC
*/

/*.wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #258ea6;
  }
  
  .form-wrapper {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px #555;
    background-color: #ffffff;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  h1 {
    text-align: center;
    width: 100%;
    color: #111;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  label {
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #222;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #cfcfcf;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #999;
  }
  
  input.error {
    border: 1px solid red;
  }
  
  .errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
  }
  
  .firstName {
    margin-right: 1%;
  }
  
  .lastName {
    margin-left: 1%;
  }
  
  .firstName,
  .lastName,
  .email,
  .password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .firstName,
  .lastName {
    width: 49%;
  }
  
  .email,
  .password {
    width: 100%;
  }
  
  .createAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .createAccount button {
    background-color: #519e8a;
    color: #fff;
    border: 2px solid #fff;
    width: 100%;
    margin-top: 1em;
    padding: 8px 0px;
    font-size: 1em;
    font-weight: lighter;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
  }
  
  .createAccount button:hover {
    color: #519e8a;
    background-color: #fff;
    border: 2px solid #519e8a;
  }
  
  .createAccount small {
    color: #999;
    font-weight: lighter;
  }*/
.view {
    background: url("https://mdbootstrap.com/img/Photos/Others/img (42).jpg")no-repeat center center;
    background-size: cover;
}
@media (min-width: 768px) {
    .view {
        overflow: visible;
        margin-top: -56px;
    }
}
.navbar {
    z-index: 1;
}
.navbar {
    background-color: transparent;
}
.top-nav-collapse {
    background-color: #4285F4;
}
@media only screen and (max-width: 768px) {
    .navbar {
        background-color: #4285F4;
    }
}

html,
body,
header,
.view {
  height: 100%;
}

form {
    margin: 20px auto;
    width: 440px;
    color: steelblue;

}
input {
    padding: 10px;
    font-size: inherit;
}

input[type="text"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}

input[type="email"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue; 
    background: #FAF6D9;  
}
input[type="password"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;   
}
select[type="select"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
.PhoneInput[type="tel"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
.form-control{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
   .button {
      
       border: none;
       background: steelblue;
       justify-content: center;
      }

      input:focus {
         background: white;
      }

      h3{
          text-align: center;
          font-size: 40px;
          color: steelblue;
          font-family:  sans-serif;

      }
     
       input[type="submit"]{
        width: 440px;
        height:45px;
        border: none;
        background:steelblue;
        color: white;

       }
       input[type="submit"]:focus{
        width: 440px;
        height:45px;
        border: none;
        background:white;
        color: steelblue;

       }


       @media only screen and (min-width : 150px) and (max-width : 480px){
        input[type="text"], input[type="password"], input[type="email"] {
          padding: 10px 10px;
          border-radius: 5px;
          outline: none;
          border: 1px solid #cfcfcf;
          margin-right: 5em;
          display: flex;
          max-width:320px;
          border: 2px solid steelblue;
    background: #FAF6D9;   
      
        }

        select[type="select"]{
            padding: 10px 10px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #cfcfcf;
            margin-right: 5em;
            display: flex;
            max-width:320px;
            border: 2px solid steelblue;
      background: #FAF6D9; 
        }
        .PhoneInput[type="tel"]{
            padding: 10px 10px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #cfcfcf;
            margin-right: 5em;
            display: flex;
            max-width:320px;
            border: 2px solid steelblue;
      background: #FAF6D9; 
        }
    }
.Modal_header{
    background-color: #CCCCCC;
    }
    .Modal_footer{
        background-color: #CCCCCC;
    }

    .Modal_body{
        background-color: #e8f3f8 !important;
    }
body{ margin: 0;
    font-family: Segoe, Segoe UI, DejaVu Sans, Trebuchet MS, Verdana," sans-serif";
       font-size: 1rem;
       line-height: 1.5;
       color: #333;
       overflow-x: hidden;
   }
   .input-group.md-form.form-sm.form-1 input {
    border: 1px solid #bdbdbd;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
   
   .video-header{height: 100vh;
            display: flex;
            align-items: center;
            color: #fff;
       
   }
   .centered {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.snow{
  width: 100%;

}
.shum1{
  margin-top: 5px;
}
.shum3{
  margin-top: -9px;
  margin-left: 95px;
border: 1px solid grey;  
}
.shum4{
  margin-top: -9px;
  border: 1px solid grey;  


}
   
   .wrap{
       max-width: 960px;
       padding-left: 1rem;
       padding-right: 1rem;
       margin:auto;
       text-align: center;
       
   }
   .fullscreen-video-wrap{
       position: absolute;
       top: 0;
       left: 0;
       height: 100vh;
       width: 100%;
       overflow: hidden;
       
   }
   .fullscreen-video-wrap video{
       min-height: 100%;
       min-width: 100%;
       
   }
   
   .header-overlay{
       height: 100vh;
       width: 100vw;
       position: absolute;
       top: 0;
       left: 0;
       background: rgba(34,33,33,0.65);
       opacity: 0.80;
       
   }
   
   .header-content{
       z-index: 2;
       margin-top:20%;
       padding: 0 5rem;
       
   }
   
   .header-content h1{
       font-size: 50px;
       margin-bottom: 0;
       
   }
   .header-content p{
       font-size: 1.5rem;
       display: block;
       padding-bottom: 2rem;
       margin-top: 10px;
   }


   @media screen and (max-width: 480px)
{
.kardi{
  max-height: 200px;
}
.tobe{
  margin-bottom: -600px;

}
.container{
  width: 100%;
  height:100%;
}
}

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 9px;
   font-weight: 70;
   min-height: 400px;
  }
   
   #head1{
       font-size: 60px;
       color: white;
       font-weight: 50;
       font-family: 'Comic sans ms';
   }
   .my-btn{
       padding: 1rem 1rem;
   }

   .modaly {
    display: inline;
   }
   .modaly div {
    color:white;
       font-size: 16px;
       display: block;
       padding: 8px;
     }
   
  div.dotted{
    border-style: solid;
    border-color: grey;
    border-width: 2px;
    border-radius: 3px;
    background-color: #D3D3D3;
      
      

  }

   @media(max-width:768px){
       .header-content h1{font-size: 40px;}
       .header-content p{font-size: 1rem;}
       .modaly{
        
        margin-left:8px;

       }
   }
   
   section{text-align: center; margin-top: 50px; font-size: 1.5rem;}
   
   .navbar{z-index: 10;}
   .my-nav .nav-link{color: aliceblue;}
   .my-nav .navbar-brand{color: aliceblue;}
   .navbar-toggler-icon{color: aliceblue;}
   
 .scarshome{
    
  float: left;
  margin-right: 5px;
}
.scars1home{
  overflow: auto;
  margin-left: 0px;
  padding-top: 5px;
  font-size: 15px;
}



.ajnabehome{
  border-style: solid;
  border-color: grey;
  border-width: 2px;
  border-radius: 3px;
  height: 48px;
}
@media screen and (max-width: 480px)
{
  h1.textwhite.head99 {
    font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    text-shadow: 2px 2px 4px #000000;
    font-size: 15px;
    font-weight: 600;
    margin: 10px;
    min-height: 400px;
}
}
.smalltext{
  font-size: 13px;
}
.smalltext1{
  font-size: 14px;
}


.pahayche{
    border-style: solid;
    border-color: grey;
    border-width: 2px;
    border-radius: 5px;
    background-color: #F0F0F0;

}
.ajnabehomie{
  border-style: solid;
  border-color: grey;
  border-width: 2px;
  border-radius: 3px;
  height: 55px;
}

.hastarcacg{
  color: black;
  text-align: center;
}

.heathen{
    border:2px solid #808080;
    padding: 20px;
    background-color: #F0F0F0;
    max-width: 100%;
}

.chop{
	width:270px; 
}

.lightgrey{
	background-color: #DCDCDC;
}


.bulletproof{
	height: 50px;

}
.hds{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}
.pqr{
  margin-top: 8px;
}
@media screen and (max-width: 480px)
{

.navbar .container {
  width: 100%;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
.navbar .nav-item .nav-link {
  padding-right: 6px;
  padding-left: 0px;
}

}
@media screen and (max-width: 934px)
{
  .navbar .nav-item .nav-link {
  padding-right: 6px;
  padding-left: 0px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
div.pqr.nav-item {
  padding-left: 0px;
  width: 0px;
}
form.hds.form-inline {
  margin-left: 0px;
  margin-right: 0px;
}

}
#zhipri {
	color:white;
}

.butalpha {
	background-color: grey;
	width: 90%;
	font-size: 200px;
	border-style: solid;
	border-color: white;
	border-width: 1px;
	 
}


.mahi{
	font-size: 20px;
}
.stayaway{
	margin-bottom: 45px;
}


.scars{
		
	float: left;
	margin-right: 5px;
}
.scars1{
	overflow: auto;
	margin-left: 2px;
	padding-top: 15px;
}



.ajnabe{
	border-style: solid;
	border-color: grey;
	border-width: 3px;
	border-radius: 3px;
	background-color: #D3D3D3;
	height: 80px;
}

.samzha{
	background-color: #E0E0E0;
}
.zombie{
	width:30px;
	text-align: center;
	background-color: #686868;

}
.mumbai{
	color: white;

}

@media only screen and (max-width: 600px) {
	.butgrp{
		display: none;
	}

	.ShowOnMob{
		display: block;
	}

}
@media only screen and (min-width: 600px){
	.ShowOnMob{
		display: none;
	}
}

@media only screen and (min-width:1100px){
.needmoney{
	float: left;
  width: 20%;
}
.onemore{
	content: "";
  clear: both;
  display: table;
}
}


.hello {
    max-width: 100%;
}
.wish{
	padding: 2px;
margin-bottom: 600px;
overflow: hidden;
}
.ViewOnMob {
  display:none;

}

.HideOnMob{
  display: block;
}
@media screen and (max-width: 480px){
.pathava{
  height: 250px;
}
}
@media screen and (max-width: 480px){
  .HideOnMob{
    display:none;
  }
}

@media screen and (max-width: 480px)
{
.ViewOnMob{
  display:block;
}
}

.chandni{
  scroll-behavior: smooth;
}


.bar2{
  background: #2E2E2E;
  padding-bottom: 5px;
}

.but2{
  color:white;
  background-color: grey;
  width: 140px;
}

.cont1{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  background-color: #F8F8F8;


}

.cont12{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  overflow: auto;  
  background-color: #F8F8F8;

}
.cont123{
  border:3px solid #808080;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  max-width: 97%;
  overflow:auro;
  height: 330px;
  background-color: #F8F8F8;


  
}



.heading{
  font-style: normal;
  font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  text-shadow: 2px 2px 4px #000000;
  font-size: 50px;
  letter-spacing: 0px;
  font-weight: 600;
  
}


.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 50px;
   font-weight: 600;
   margin-top: 80px;
 }

@media screen and (max-width: 480px){

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 20px;
   font-weight: 100;
   margin: 10px;
   min-height: 400px;
  }
}

.image1{
   
  height: 550px;
  display:block;
  margin-left:auto;
  margin-right: auto;
}

.container1 {
  position: relative;
  width: 10%;
  float: left;
  margin-left: 20px;
}

.image1 {
  display: block;
  width: 70%;
  height: auto;
}


.cortry{
  min-height: 600px;
  background-image: url(http://www.emanjha.com/emanjha/images/5c4f1bf949fd7.jpg);
  background-repeat: no-repeat; 
  background-size: 1700px 600px;
  background-position: center top;


 } 
 .cortry1{
    width: 100%;   
    background-size: 100%;
    background-repeat: no-repeat;
    height: 600px;
 }

.bottomrt{
   position: absolute;
  bottom: 0px;
  right: 0px;
  width: 300px;
}
.bottom{
  position: absolute;
  bottom:0px;
}

@media screen and (max-width: 480px){
  .overlay{
    display:none;
  }
  .text1{
    display:none;
  }
  .overlay:hover {
    display: none;
  }
  .container1:hover {
    display: none;
  }
}

@media screen and (max-width:480px){

  .SmallOnMob {
    font-size: 10px;
    padding: 5px;
  }

}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 70%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: #008CBA;
  display: block;
}

.container1:hover .overlay {
  opacity: 0.7   ;
}

.text1 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}


.conta{
	border:3px solid #808080;
	width: 799px;
	height: 90px;
	background-color: gray;
	color: white;
	padding: 0px;
	background-image: -webkit-linear-gradient(top left, #404040, #606060);
	background-image: linear-gradient(to bottom right, #404040, #606060);

}
.hastar{
	font-size: 40px;
	
}
.hi{
	float:right;
	list-style-type: none;
	padding: 0px;

}

.contar1{
	border:3px solid #808080;
	width: 100%;
	height: 200px;
	background-color: gray;
	color: white;
	padding: 1rem;
	background-image: -webkit-linear-gradient(top left, #404040, #606060);
	background-image: linear-gradient(to bottom right, #404040, #606060);
	border-radius: 15px;

}

.contar2{
	height: 70px;
}

.contar3{
	height: 70px;
}
@media screen and (max-width:380px){
	.conta1{
		width: 270px;
	}

}

.acg{
	padding: 0rem;
background-color: grey;
border: 2px solid #fff;
color: #fff;
text-align: center;
 border-radius: 10px;

}
.hi{
	float:right;
	list-style-type: none;
	padding: 0px;

}


.athvan{
	background-color: grey;
	margin: 0px;
	padding: 0px;
	width: 100vw;
	text-align: center;
	color: white;
}

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '\2605   ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}


.sda{
    float: left;
}

.motha{
    font-size: 30px;
}



.missng{

    width: 50%;
}

@media screen and (max-width: 480px){





.csdd{
 border: 2px solid grey;  
 border-radius: 10px;
 margin: 5px;
 padding: 5px;
}
}

.csdd{
 border: 2px solid grey;  
 border-radius: 10px;
 margin: 20px;
 padding: 10px;
}

.wohu{
	height: 25vw;
	width: 40rem;
}

@media screen and (max-width: 480px){
	.wohu{
		height:50vw;
	}
}
.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 60px;
   font-weight: 600;
   margin-top: 80px;
 }

@media screen and (max-width: 480px){

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 25px;
   font-weight: 600;
   margin: 10px;
   min-height: 400px;
  }
}


.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: "Show more";
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: "Show less";
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5em;
  color: #666;
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}



.conta{
	border:3px solid #808080;
	width: 560px;
	height: 90px;
	background-color: gray;
	color: white;
}

@media only screen and (min-width: 600px){
	.chaapchaap{
		width: 70%;
		

}
}

.chaapchaap{
	border-style: double;
		border-radius: 10px;
}
.needmoney1{
	margin:2px;
}


.onemore{
	content: "";
  clear: both;
  display: table;
}

.needmoney{
	float: left;
  width: 20%;
}


.again{
	font-size: 50px;
}

.dropl{
	max-width:90%; 
}
