.contar1{
	border:3px solid #808080;
	width: 100%;
	height: 200px;
	background-color: gray;
	color: white;
	padding: 1rem;
	background-image: linear-gradient(to bottom right, #404040, #606060);
	border-radius: 15px;

}

.contar2{
	height: 70px;
}

.contar3{
	height: 70px;
}
@media screen and (max-width:380px){
	.conta1{
		width: 270px;
	}

}

.acg{
	padding: 0rem;
background-color: grey;
border: 2px solid #fff;
color: #fff;
text-align: center;
 border-radius: 10px;

}
.hi{
	float:right;
	list-style-type: none;
	padding: 0px;

}

