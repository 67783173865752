#zhipri {
	color:white;
}

.butalpha {
	background-color: grey;
	width: 90%;
	font-size: 200px;
	border-style: solid;
	border-color: white;
	border-width: 1px;
	 
}


.mahi{
	font-size: 20px;
}
.stayaway{
	margin-bottom: 45px;
}


.scars{
		
	float: left;
	margin-right: 5px;
}
.scars1{
	overflow: auto;
	margin-left: 2px;
	padding-top: 15px;
}



.ajnabe{
	border-style: solid;
	border-color: grey;
	border-width: 3px;
	border-radius: 3px;
	background-color: #D3D3D3;
	height: 80px;
}

.samzha{
	background-color: #E0E0E0;
}
.zombie{
	width:30px;
	text-align: center;
	background-color: #686868;

}
.mumbai{
	color: white;

}

@media only screen and (max-width: 600px) {
	.butgrp{
		display: none;
	}

	.ShowOnMob{
		display: block;
	}

}
@media only screen and (min-width: 600px){
	.ShowOnMob{
		display: none;
	}
}

@media only screen and (min-width:1100px){
.needmoney{
	float: left;
  width: 20%;
}
.onemore{
	content: "";
  clear: both;
  display: table;
}
}

