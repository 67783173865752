
form {
    margin: 20px auto;
    width: 440px;
    color: steelblue;

}
input {
    padding: 10px;
    font-size: inherit;
}

input[type="text"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}

input[type="email"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue; 
    background: #FAF6D9;  
}
input[type="password"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;   
}
select[type="select"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
.PhoneInput[type="tel"]{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
.form-control{
    display: block;
    margin-bottom: 25px;
    width:100%;
    border: 2px solid steelblue;
    background: #FAF6D9;
}
   .button {
      
       border: none;
       background: steelblue;
       justify-content: center;
      }

      input:focus {
         background: white;
      }

      h3{
          text-align: center;
          font-size: 40px;
          color: steelblue;
          font-family:  sans-serif;

      }
     
       input[type="submit"]{
        width: 440px;
        height:45px;
        border: none;
        background:steelblue;
        color: white;

       }
       input[type="submit"]:focus{
        width: 440px;
        height:45px;
        border: none;
        background:white;
        color: steelblue;

       }


       @media only screen and (min-width : 150px) and (max-width : 480px){
        input[type="text"], input[type="password"], input[type="email"] {
          padding: 10px 10px;
          border-radius: 5px;
          outline: none;
          border: 1px solid #cfcfcf;
          margin-right: 5em;
          display: flex;
          max-width:320px;
          border: 2px solid steelblue;
    background: #FAF6D9;   
      
        }

        select[type="select"]{
            padding: 10px 10px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #cfcfcf;
            margin-right: 5em;
            display: flex;
            max-width:320px;
            border: 2px solid steelblue;
      background: #FAF6D9; 
        }
        .PhoneInput[type="tel"]{
            padding: 10px 10px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #cfcfcf;
            margin-right: 5em;
            display: flex;
            max-width:320px;
            border: 2px solid steelblue;
      background: #FAF6D9; 
        }
    }