.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}


.sda{
    float: left;
}

.motha{
    font-size: 30px;
}



.missng{

    width: 50%;
}

@media screen and (max-width: 480px){





.csdd{
 border: 2px solid grey;  
 border-radius: 10px;
 margin: 5px;
 padding: 5px;
}
}

.csdd{
 border: 2px solid grey;  
 border-radius: 10px;
 margin: 20px;
 padding: 10px;
}
