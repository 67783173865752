body{ margin: 0;
    font-family: Segoe, Segoe UI, DejaVu Sans, Trebuchet MS, Verdana," sans-serif";
       font-size: 1rem;
       line-height: 1.5;
       color: #333;
       overflow-x: hidden;
   }
   .input-group.md-form.form-sm.form-1 input {
    border: 1px solid #bdbdbd;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
   
   .video-header{height: 100vh;
            display: flex;
            align-items: center;
            color: #fff;
       
   }
   .centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.snow{
  width: 100%;

}
.shum1{
  margin-top: 5px;
}
.shum3{
  margin-top: -9px;
  margin-left: 95px;
border: 1px solid grey;  
}
.shum4{
  margin-top: -9px;
  border: 1px solid grey;  


}
   
   .wrap{
       max-width: 960px;
       padding-left: 1rem;
       padding-right: 1rem;
       margin:auto;
       text-align: center;
       
   }
   .fullscreen-video-wrap{
       position: absolute;
       top: 0;
       left: 0;
       height: 100vh;
       width: 100%;
       overflow: hidden;
       
   }
   .fullscreen-video-wrap video{
       min-height: 100%;
       min-width: 100%;
       
   }
   
   .header-overlay{
       height: 100vh;
       width: 100vw;
       position: absolute;
       top: 0;
       left: 0;
       background: rgba(34,33,33,0.65);
       opacity: 0.80;
       
   }
   
   .header-content{
       z-index: 2;
       margin-top:20%;
       padding: 0 5rem;
       
   }
   
   .header-content h1{
       font-size: 50px;
       margin-bottom: 0;
       
   }
   .header-content p{
       font-size: 1.5rem;
       display: block;
       padding-bottom: 2rem;
       margin-top: 10px;
   }


   @media screen and (max-width: 480px)
{
.kardi{
  max-height: 200px;
}
.tobe{
  margin-bottom: -600px;

}
.container{
  width: 100%;
  height:100%;
}
}

.head99{
   font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   text-shadow: 2px 2px 4px #000000;
   font-size: 9px;
   font-weight: 70;
   min-height: 400px;
  }
   
   #head1{
       font-size: 60px;
       color: white;
       font-weight: 50;
       font-family: 'Comic sans ms';
   }
   .my-btn{
       padding: 1rem 1rem;
   }

   .modaly {
    display: inline;
   }
   .modaly div {
    color:white;
       font-size: 16px;
       display: block;
       padding: 8px;
     }
   
  div.dotted{
    border-style: solid;
    border-color: grey;
    border-width: 2px;
    border-radius: 3px;
    background-color: #D3D3D3;
      
      

  }

   @media(max-width:768px){
       .header-content h1{font-size: 40px;}
       .header-content p{font-size: 1rem;}
       .modaly{
        
        margin-left:8px;

       }
   }
   
   section{text-align: center; margin-top: 50px; font-size: 1.5rem;}
   
   .navbar{z-index: 10;}
   .my-nav .nav-link{color: aliceblue;}
   .my-nav .navbar-brand{color: aliceblue;}
   .navbar-toggler-icon{color: aliceblue;}
   
 .scarshome{
    
  float: left;
  margin-right: 5px;
}
.scars1home{
  overflow: auto;
  margin-left: 0px;
  padding-top: 5px;
  font-size: 15px;
}



.ajnabehome{
  border-style: solid;
  border-color: grey;
  border-width: 2px;
  border-radius: 3px;
  height: 48px;
}
@media screen and (max-width: 480px)
{
  h1.textwhite.head99 {
    font-family: 'Benton Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    text-shadow: 2px 2px 4px #000000;
    font-size: 15px;
    font-weight: 600;
    margin: 10px;
    min-height: 400px;
}
}