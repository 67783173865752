.onemore{
	content: "";
  clear: both;
  display: table;
}

.needmoney{
	float: left;
  width: 20%;
}

